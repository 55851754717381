<div>
    <div>
        <a routerLink="/forms-list" class="btn btn-primary btn-sm mb-4 mt-2">
            <span class="fa fa-chevron-left mr-2" aria-hidden="true"></span>Voltar
        </a>
    </div>

    <div class="m-5">
        <p-steps [model]="items" [readonly]="true" [(activeIndex)]="activeIndex"></p-steps>
    </div>

    <div class="mb-4">
        <div *ngIf="activeIndex === 0" class="d-flex justify-content-end">
            <button type="button" class="btn btn-primary" (click)="nextStep()" [disabled]="!isStepOneIsValid()">
                <div class="d-flex align-items-center">
                    <span class="ml-2">Próximo</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                    </svg>
                </div>
            </button>
        </div>
        <div *ngIf="activeIndex === 1" class="d-flex justify-content-between">
            <button type="button" class="btn btn-outline-primary" (click)="prevStep()">
                <div class="d-flex align-items-center">
                    <svg class="sidebar-icon-outline" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <polyline points="15 18 9 12 15 6"></polyline>
                    </svg>
                    <span class="mr-2">Anterior</span>
                </div>
            </button>
            <button type="button" class="btn btn-primary" (click)="nextStep()" [disabled]="!isStepTwoIsValid()" >
                <div class="d-flex align-items-center">
                    <span class="ml-2">Próximo</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                    </svg>
                </div>
            </button>
        </div>
        <div *ngIf="activeIndex === 2" class="d-flex justify-content-between">
            <button type="button" class="btn btn-outline-primary" (click)="prevStep()">
                <div class="d-flex align-items-center">
                    <svg class="sidebar-icon-outline" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <polyline points="15 18 9 12 15 6"></polyline>
                    </svg>
                    <span class="mr-2">Anterior</span>
                </div>
            </button>
            <button type="button" class="btn btn-primary" (click)="onComplete()" [disabled]="!formGroup.valid">
                <div class="d-flex align-items-center">
                    <span class="ml-2 mr-2">Concluir</span>
                </div>
            </button>
        </div>
    </div>

    <div *ngIf="!formGroup.valid && activeIndex === 2" class="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>Atenção!</strong> Existem campos não preenchidos!
    </div>

    <form [formGroup]="formGroup">
        <div *ngIf="activeIndex === 0">
            <div class="card shadow">
                <div class="card-body">
                    <p class="permission-title">Permissões</p>
                    <p class="permission-subtitle mb-4">Selecione para qual empresa(s), departamento(s) e cargo(s) o formulário será enviado.</p>
                    <div class="form-row">
                        <div class="form-group col-lg-6">
                            <app-select-company (selectEvent)="selectValues($event)"></app-select-company>
                        </div>

                        <div class="form-group col-lg-3">
                            <label>Departamento *</label>
                            <div class="d-flex justify-content-start align-items-center">
                                <select class="form-control w-100" id="cargo" formControlName="departmentId"  style="width: 50%;"
                                        [ngClass]="{
                                        'is-invalid': formGroup && formGroup.get('departmentId')?.hasError('required') &&
                                         formGroup.get('departmentId')?.touched
                                    }">
                                    <option value="">Selecione um Departamento</option>
                                    <option *ngFor="let department of departments"
                                            value="{{department.id}}">{{department.name}}</option>
                                </select>
                            </div>
                            <div class="custom-invalid-feedback" *ngIf="formGroup && formGroup.get('departmentId')?.hasError('required')
                                        && formGroup.get('departmentId')?.touched">
                                Departamento é requerido
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div *ngIf="activeIndex === 1">
            <div class="card shadow">
                <div class="card-body">
                    <p class="permission-title">Período</p>
                    <p class="permission-subtitle mb-4">Selecione o período que esse formulário estará ativo</p>

                    <div class="row">
                        <div class="col-4">
                            <p class="searchbar-label">Data Inicial*</p>
                            <p-calendar
                                #startDate
                                [showIcon]="false"
                                placeholder="Selecione uma data"
                                formControlName="startDate"
                                dateFormat="dd/mm/yy"
                                inputStyleClass="form-control">
                            </p-calendar>
                        </div>
                        <div class="col-4">
                            <p class="searchbar-label">Data Final*</p>
                            <p-calendar
                                [showIcon]="false"
                                placeholder="Selecione uma data"
                                [minDate]="startDate.value"
                                formControlName="endDate"
                                dateFormat="dd/mm/yy"
                                inputStyleClass="form-control">
                            </p-calendar>
                        </div>
                        <div class="col-4"></div>
                    </div>
                </div>
            </div>

        </div>

        <div *ngIf="activeIndex === 2">
            <div class="card shadow">
                <div class="card-body">
                    <p class="permission-title">Formulário</p>
                    <p class="permission-subtitle mb-4">Crie questões abertas, fechadas, de múltipla escolha ou única escolha.</p>

                    <div class="form-row">
                        <div class="form-group col-12">
                            <label for="form-name">Nome do formulário</label>
                            <input
                                id="form-name"
                                type="text"
                                placeholder="Escreva aqui"
                                maxlength="120"
                                class="form-control"
                                formControlName="formName"
                            >
                        </div>
                    </div>
                </div>
            </div>
            <!-- DYNAMIC FORM -->
            <div class="card shadow mt-5" formArrayName="questions" *ngFor="let question of questions.controls; let i = index">
                <div [formGroupName]="i">
                    <div class="card-body">
                        <!-- START ANSWER FORM -->
                        <div>
                            <div class="form-row">
                                <div class="form-group col-8">
                                    <label for="question">Pergunta</label>
                                    <input
                                        id="question"
                                        type="text"
                                        placeholder="Escreva aqui"
                                        maxlength="100"
                                        class="form-control"
                                        formControlName="question"
                                    >
                                </div>

                                <div class="form-group col-4">
                                    <label for="type-question">Tipo de pergunta</label>
                                    <select
                                        id="type-question"
                                        class="form-control"
                                        formControlName="type"
                                    >
                                        <option value="ONE_CHOICE_TYPE">Seleção Única</option>
                                        <option value="CHOICE_TYPE">Seleção Múltipla</option>
                                        <option value="SHORT_TEXT_TYPE">Resposta curta</option>
                                        <option value="LONG_TEXT_TYPE">Resposta longa</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-row" *ngIf="question.get('type')!.value === 'ONE_CHOICE_TYPE'">
                                <div class="form-group col-8" formArrayName="options"  *ngFor="let option of getOptions(question).controls; let j = index">
                                    <div [formGroupName]="j">
                                        <label class="flex align-items-center">
                                            <input
                                                [id]="'option-answer'+ j"
                                                type="radio"
                                                disabled
                                                class="option-radio mr-4"
                                            >
                                            <input
                                                [id]="'option-text-' + j"
                                                type="text"
                                                placeholder="Escreva aqui a opção 1"
                                                maxlength="100"
                                                class="form-control flex-grow-1"
                                                formControlName="answer"
                                            >
                                        </label>
                                    </div>
                                </div>
                                <div class="col-4"></div>
                            </div>

                            <div class="form-row" *ngIf="question.get('type')!.value === 'CHOICE_TYPE'">
                                <div class="form-group col-8" formArrayName="options"  *ngFor="let option of getOptions(question).controls; let j = index">
                                    <div [formGroupName]="j">
                                        <div class="form-check flex align-items-center">
                                            <input
                                                id="check-answer"
                                                type="checkbox"
                                                disabled
                                                class="form-check-input mb-1"

                                            >
                                            <input
                                                id="check"
                                                type="text"
                                                placeholder="Escreva aqui a opção 1"
                                                maxlength="100"
                                                class="form-control flex-grow-1 ml-4"
                                                formControlName="answer"
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4"></div>
                            </div>

                            <div class="form-row" *ngIf="question.get('type')!.value === 'SHORT_TEXT_TYPE' ||
                                question.get('type')!.value === 'LONG_TEXT_TYPE'">
                                <div class="form-group col-8">
                                    <label for="answer">
                                        {{ question.get('type')!.value === 'SHORT_TEXT_TYPE' ? 'Texto de resposta curta' : 'Texto de resposta longa' }}
                                    </label>
                                    <input
                                        id="answer"
                                        type="text"
                                        class="form-control flex-grow-1"
                                        formControlName="answer"
                                    >
                                </div>
                                <div class="col-4"></div>
                            </div>

                            <button
                                type="button"
                                class="btn btn-link btn-sm text-primary ml-3"
                                *ngIf="question.get('type')!.value !== 'SHORT_TEXT_TYPE' && question.get('type')!.value !== 'LONG_TEXT_TYPE'"
                                (click)="addOption(i)">
                                Adicionar Opção
                            </button>
                        </div>

                        <!-- END ANSWER FORM -->

                        <hr>

                        <div class="d-flex justify-content-end">
                            <!-- DUPLICAR PERGUNTA -->
                            <button class="btn btn-link text-primary mr-2 p-0" type="button" (click)="duplicateQuestion(i)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
                                </svg>
                            </button>

                            <!-- REMOVER PERGUNTA -->
                            <button class="btn btn-link text-danger mr-2 p-0" type="button" (click)="removeQuestion(i)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                            </button>

                            <div class="d-flex align-items-center">
                                <label class="mr-2 mb-0" [for]="'required' + i">Obrigatória</label>
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" formControlName="required" [id]="'required' + i">
                                    <label class="custom-control-label" [for]="'required' + i"></label>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <button type="button" class="btn btn-primary mt-3" (click)="addQuestion()">
                <div class="d-flex align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4" />
                    </svg>
                    <span class="ml-2">Adicionar Pergunta</span>
                </div>
            </button>
        </div>
    </form>
</div>
