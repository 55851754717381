<p-toast></p-toast>

<p-dialog [(visible)]="showClockAjustmentSuccess" [style]="{width: '35%'}" [draggable]="false" [position]="modalPosition">
    <ng-template pTemplate="header">
        <span>
            <img class="hours-adjustment-modal-icon" src="assets/icon/CheckCircle/check-circle.svg" />
            Ajuste solicitado com sucesso!
            <span class="badge badge-link cursor" (click)="onRejectClockAdjustment(clockAdjustmentTemp)">
                Desfazer
            </span>
        </span>
    </ng-template>
</p-dialog>

<p-dialog header="Solicitar ajuste" [(visible)]="showClockAjustment" [style]="{width: '65%'}">
    <div>
        <div>
            <form [formGroup]="form">
                <div class="form-row">
                    <div class="form-group col-lg-3">
                        <div class="p-field mr-4">
                            <label class="label-period" for="startTimeFirstPeriod">Início Primeiro Período *</label>
                            <p-inputMask class="p-inputtext-sm" id="startTimeFirstPeriod" placeholder="Ex: 00:00" mask="99:99"
                                formControlName="startTimeFirstPeriod"
                                [ngClass]="{ 'is-invalid': form.get('startTimeFirstPeriod')?.hasError('required') &&
                                    form.get('startTimeFirstPeriod')?.touched}">
                            </p-inputMask>
                            <div class="invalid-feedback" *ngIf="form.get('startTimeFirstPeriod')?.hasError('required')
                                && form.get('startTimeFirstPeriod')?.touched">
                                Campo requerido
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-lg-3">
                        <label class="label-period" for="endTimeFirstPeriod">Fim Primeiro Período *</label>
                        <div class="p-field mr-4">
                            <p-inputMask class="p-inputtext-sm" id="endTimeFirstPeriod" placeholder="Ex: 00:00" mask="99:99"
                                formControlName="endTimeFirstPeriod"
                                [ngClass]="{
                                    'is-invalid': form.get('endTimeFirstPeriod')?.hasError('required') && form.get('endTimeFirstPeriod')?.touched}">
                            </p-inputMask>
                            <div class="invalid-feedback" *ngIf="form.get('endTimeFirstPeriod')?.hasError('required')
                                && form.get('endTimeFirstPeriod')?.touched">
                                Campo requerido
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-lg-3">
                        <label class="label-period" for="startTimeSecondPeriod">Início Segundo Período *</label>
                        <div class="p-field mr-4">
                            <p-inputMask class="p-inputtext-sm" id="startTimeSecondPeriod" placeholder="Ex: 00:00" mask="99:99"
                                formControlName="startTimeSecondPeriod"
                                [ngClass]="{
                                    'is-invalid': form.get('startTimeSecondPeriod')?.hasError('required') && form.get('startTimeSecondPeriod')?.touched}">
                            </p-inputMask>
                            <div class="invalid-feedback" *ngIf="form.get('startTimeSecondPeriod')?.hasError('required')
                                && form.get('startTimeSecondPeriod')?.touched">
                                Campo requerido
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-lg-3">
                        <label class="label-period" for="endTimeSecondPeriod">Fim Segundo Período *</label>
                        <div class="p-field">
                            <p-inputMask class="p-inputtext-sm" id="endTimeSecondPeriod" placeholder="Ex: 00:00" mask="99:99"
                                formControlName="endTimeSecondPeriod"
                                [ngClass]="{
                                    'is-invalid': form.get('endTimeSecondPeriod')?.hasError('required') && form.get('endTimeSecondPeriod')?.touched}">
                            </p-inputMask>
                            <div class="invalid-feedback" *ngIf="form.get('endTimeSecondPeriod')?.hasError('required')
                                && form.get('endTimeSecondPeriod')?.touched">
                                Campo requerido
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <p-footer>
        <button class="btn btn-link btn-cancel" (click)="onCloseHoursAdjustment()">Cancelar</button>
        <button class="btn btn-primary w-3" (click)="sendClockAdjustment()">Solicitar Ajuste</button>
    </p-footer>
</p-dialog>

<div style="height: 50px; margin-bottom: 35px;">
    <div class="current-time animated fadeIn slow" *ngIf="currentDateTime">
        <div class="d-flex align-items-center">
            <div class="mr-2">
                <span class="timer">{{ currentDateTime | async | date:'HH:mm'}}</span>
            </div>
            <div class="border-left-primary mr-2">
                <span class="ml-2">{{ currentDateTime | async | date:'d'}} de {{ currentDateTime | async | date:'MMMM' }} de {{ currentDateTime | async | date:'yyyy' }}</span>
            </div>
            <div class="border-left-primary mr-2">
                <span class="ml-2">{{ currentDateTime  | async | date:'EEEE' | titlecase}}</span>
            </div>
            <div class="border-left-primary mr-2">
                <span class="ml-2"></span>
            </div>
            <div *ngIf="workRule">
                <span class="badge badge-blue">
                    {{ workRule }}
                </span>
            </div>
        </div>
    </div>
</div>

<div class="circle-wrap">
    <div role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" [style]="{'--value':sumOfPeriods}">
        <app-chronomete
            #chronometer
            [minutes]="minutes"
            [seconds]="seconds"
            [hours]="hours"
            (clock)="clockEvent($event)">
        </app-chronomete>
    </div>
</div>

<div class="loader-container d-flex justify-content-center align-items-center">
    <div class="d-flex justify-content-center text-muted align-items-center p-3" *ngIf="loading">
        <span class="spinner-grow spinner-grow-sm m-2" role="status" aria-hidden="true"></span>
        {{loadMessage}}
    </div>
</div>

<div class="alert bg-info-light text-primary d-flex align-items-center shadow mb-2"
    role="alert" *ngIf="workRule === '' && registeredAddress === false">
    <i class="bi bi-exclamation-circle text-primary h3 mr-4"></i>
    <div>
        <h5>Cadastro da jornada de trabalho incompleta</h5>
        <div class="small text-primary">
            É preciso completar o cadastro da jornada de trabalho!
            <a class="cursor" (click)="goToConfigureEmployee()"> clique aqui</a>
        </div>
    </div>
</div>

<div class="d-flex justify-content-center mb-3" *ngIf="!started && onAbsence?.value === false">
    <button type="submit" class="btn btn-primary"
        (click)="startRegisterClock()" [disabled]="workRule === '' && registeredAddress === false">
            Bater Ponto
    </button>
</div>

<div class="d-flex justify-content-center mb-3" *ngIf="onAbsence?.value === true">
    <button type="submit" class="btn btn-primary" [disabled]="onAbsence.value === true">
            Você está de {{onAbsence.type === 'VACATION' ? 'Férias' : 'Licença' }}
    </button>
</div>

<div class="d-flex justify-content-center mb-3" *ngIf="started">
    <button type="submit" class="btn btn-primary" (click)="stopRegisterClock()">
            Encerrar Ponto
    </button>
</div>

<div class="card shadow mt-5">
    <div class="card-body">
        <div class="border-left-primary mr-2 mt-2 mb-4">
            <span class="ml-2 text-20-27">Registro Atual</span>
        </div>

        <div class="row">
            <div class="col-md-3 mb-2">
                <div class="border bg-light text-lef pl-2 text-sm">
                    <span class="font-weight-bolder">1ª Entrada:</span>
                </div>
            </div>
            <div class="col-md-3 mb-2">
                <div class="border bg-light text-lef pl-2 text-sm">
                    <span class="font-weight-bolder">1ª Saída:</span>
                </div>
            </div>
            <div class="col-md-3 mb-2">
                <div class="border bg-light text-lef pl-2 text-sm">
                    <span class="font-weight-bolder">2ª Entrada:</span>
                </div>
            </div>
            <div class="col-md-3 mb-2">
                <div class="border bg-light text-lef pl-2 text-sm">
                    <span class="font-weight-bolder">2ª Saída:</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div *ngFor="let register of clockRegisters" class="col-md-3 mb-2">
                <div class="border bg-light text-lef pl-2 text-sm">
                    {{ register.recordDateTime | date: 'HH:mm' }}
                </div>
            </div>
        </div>

    </div>
</div>

<div class="card shadow mt-5">
    <div class="card-body">
        <div class="loading-box d-flex justify-content-center align-items-center text-muted p-3" *ngIf="loadingTable">
            <span class="spinner-grow spinner-grow-sm m-2" role="status" aria-hidden="true"></span>
                Aguarde estamos carregando os dados...
        </div>

        <div *ngIf="!loadingTable">
            <div class="border-left-primary mr-2 mt-2 mb-4">
                <span class="ml-2 text-20-27">Último Registro</span>
            </div>

            <div class="d-flex justify-content-center text-muted" *ngIf="groupResult && groupResult.length === 0">
                <h6>Nenhum Registro de Ponto encontrado</h6>
            </div>

            <div class="table-responsive" *ngIf="groupResult && groupResult.length > 0">
                <p-table #dt
                    [value]="groupResult"
                    dataKey="id"
                    styleClass="p-datatable-employee"
                    [rowHover]="true"
                    [rows]="10"
                    [showCurrentPageReport]="true"
                    [rowsPerPageOptions]="[10,25,50]"
                    [paginator]="false"
                    [filterDelay]="0"
                    [globalFilterFields]="['employee.name','type', 'recordDate']">

                    <ng-template pTemplate="header">
                        <th>Data
                            <!-- <p-sortIcon field="data"></p-sortIcon> -->
                        </th>
                        <th>Entrada
                            <!-- <p-sortIcon field="entrada"></p-sortIcon> -->
                        </th>
                        <!-- <th>Pausa
                            <p-sortIcon field="pausa"></p-sortIcon>
                        </th> -->
                        <th>Saída
                            <!-- <p-sortIcon field="saida"></p-sortIcon> -->
                        </th>
                        <th>Saldo de horas
                            <!-- <p-sortIcon field="saldo"></p-sortIcon> -->
                        </th>
                        <th>Ações
                            <!-- <p-sortIcon field="acoes"></p-sortIcon> -->
                        </th>
                    </ng-template>

                    <ng-template pTemplate="body" let-groupClocks>
                        <tr>
                            <td style="padding: .3rem .3rem" class="cel">{{groupClocks.recordDate | date:'dd/MM/yyyy'}}</td>
                            <td>
                                <span class="b-secondary">
                                    {{groupClocks.recordTime[0].entrada}}
                                </span>
                            </td>
                            <!-- <td>...</td> -->
                            <td>
                                <span class="b-secondary">
                                    {{groupClocks.recordTime[(groupClocks.recordTime).length - 1].saida}}
                                </span>
                            </td>
                            <td>
                                <span [ngClass]="groupClocks.hoursBalance.startsWith('-') === true ?
                                    'badge badge-red' : 'badge badge-green'">
                                    {{groupClocks.hoursBalance}}
                                </span>
                            </td>
                            <td>
                                <button class="btn btn-link btn-icon-primary p-0 hours-adjustment-btn" *ngIf="groupClocks.status !== 'ACCEPTED'"
                                    (click)="onClockAdjustment(groupClocks)">
                                    <img class="hours-adjustment-icon" src="assets/icon/Cog/cog.svg"
                                        tooltipStyleClass="shadow" [escape]="false"
                                        pTooltip="Solicitar ajuste"
                                        tooltipPosition="top"/>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>

    </div>
</div>

