import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';

import {MenuItem} from 'primeng/api';
import {CompanyService} from '../../services/company.service';
import {DepartmentService} from '../../services/department.service';
import {Department} from '../../model/department';
import {Survey} from '../../model/Survey';
import {FormGeneratorService} from '../../services/form-generator.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-form-creator',
  templateUrl: './form-creator.component.html',
  styleUrls: ['./form-creator.component.scss']
})
export class FormCreatorComponent implements OnInit {

    public items: MenuItem[] = [];
    public activeIndex = 0;
    public formGroup: FormGroup;
    public thereIsBranch = false;
    public departments: Department[] = [];

  constructor(
      private companyService: CompanyService,
      private departmentService: DepartmentService,
      private formBuilder: FormBuilder,
      private formGeneratorService: FormGeneratorService,
      private router: Router,
  ) {
      this.formGroup = this.formBuilder.group({
          companyId: ['', Validators.required],
          departmentId: ['', Validators.required],
          path: [''],
          startDate: ['', Validators.required],
          endDate: ['', Validators.required],
          formName: ['', Validators.required],
          questions: this.formBuilder.array([
              this.newQuestion()
          ])
      });
  }

  ngOnInit(): void {
      this.items = [
          {
              label: 'Permissões',
              routerLink: 'permissions'
          },
          {
              label: 'Período',
              routerLink: 'period'
          },
          {
              label: 'Formulário',
              routerLink: 'form'
          },
      ];
  }

    // Inicializando uma nova pergunta
    newQuestion(): FormGroup {
        const form = this.formBuilder.group({
            question: ['', Validators.required],
            type: ['ONE_CHOICE_TYPE', Validators.required],
            answer: [{ value: '', disabled: true }],
            required: [false],
            options: this.formBuilder.array([
                this.newOption()
            ])
        });

        form.get('type')?.valueChanges.subscribe((type) => {
            if (type === 'SHORT_TEXT_TYPE' || type === 'LONG_TEXT_TYPE') {
                form.get('options')?.disable();
            } else {
                form.get('options')?.enable();
            }
        });

        return form;
    }

    get questions(): FormArray {
        return this.formGroup.get('questions') as FormArray;
    }

    getOptions(question: AbstractControl): FormArray {
        return question.get('options') as FormArray;
    }

    // Para as opções de múltipla escolha ou checkbox
    newOption(): FormGroup {
        return this.formBuilder.group({
            answer: ['', Validators.required],
        });
    }

    selectOption(question: AbstractControl<any>, selectedIndex: number): void {
        const options = this.getOptions(question);
        options.controls.forEach((option, index) => {
            option.get('isSelected')?.setValue(index === selectedIndex);
        });
    }

    // Adiciona nova pergunta ao FormArray
    addQuestion(): void {
        this.questions.push(this.newQuestion());
    }

    duplicateQuestion(index: number): void {
        const questionToDuplicate = this.questions.at(index).getRawValue();

        const duplicatedQuestion = this.formBuilder.group({
            question: [questionToDuplicate.question, Validators.required],
            type: [questionToDuplicate.type, Validators.required],
            answer: [questionToDuplicate.answer],
            required: [questionToDuplicate.required],
            options: this.formBuilder.array(
                questionToDuplicate.options.map((option: any) =>
                    this.formBuilder.group({
                        answer: [option.answer, Validators.required],
                    })
                )
            ),
        });

        this.questions.push(duplicatedQuestion);
    }

    // Adicionar nova opção
    addOption(questionIndex: number): void {
        const options = this.questions.at(questionIndex).get('options') as FormArray;
        options.push(this.newOption());
    }

    removeQuestion(index: number): void {
        this.questions.removeAt(index);
    }

    public nextStep(): void {
        if (this.activeIndex < this.items.length - 1) {
            this.activeIndex++;
        }
    }

    public prevStep(): void {
        if (this.activeIndex > 0) {
            this.activeIndex--;
        }
    }

    public isStepOneIsValid(): boolean {
      const companyId = this.formGroup.get('companyId')?.value;
      const departmentId = this.formGroup.get('departmentId')?.value;
      return !!(companyId && departmentId);
    }

    public isStepTwoIsValid(): boolean {
      const startDate = this.formGroup.get('startDate')?.value;
      const endDate = this.formGroup.get('endDate')?.value;
      return !!(startDate && endDate);
    }

    async selectValues(value: any): Promise<void> {
        const {companyId, branchId, path} = value;

        const selectedCompany = await this.companyService.get(companyId).toPromise();

        if (selectedCompany?.data?.type) {
            this.thereIsBranch = await this.companyService.thereIsBranch(companyId, selectedCompany.data.type);
        }

        if (companyId !== '' && branchId !== '') {
            this.formGroup?.get('companyId')?.setValue(branchId);
            this.formGroup?.get('path')?.setValue(path);
            this.getDepartments(branchId);
        } else {
            this.formGroup?.get('companyId')?.setValue(companyId);
            this.formGroup?.get('path')?.setValue(path);
            this.getDepartments(companyId);
        }
    }

    async getDepartments(companyId: string): Promise<void> {
        this.departments = [];
        this.formGroup?.get('departmentId')?.disable();
        try {
            this.departmentService.list(companyId).subscribe( departments => {
                const result: Department[] = [];
                departments?.data?.map((dep: Department) => {
                    if (dep.status === 'ACTIVE') {
                        result.push(dep);
                    }
                });

                this.departments = result;
                if (this.departments.length === 0) {
                    this.formGroup?.get('departmentId')?.disable();
                } else {
                    this.formGroup?.get('departmentId')?.enable();
                }
            });
        } catch (error: any) {}
    }

    async onComplete(): Promise<void> {
        const questionsWithNumbers = this.questions.controls.map((question, index) => ({
            ...question.value,
            number: index + 1
        }));

        const survey: Survey = {
            name: this.formGroup.get('formName')?.value,
            enabled: true,
            holderId: this.formGroup.get('path')?.value,
            companyId: this.formGroup.get('companyId')?.value,
            departmentId: this.formGroup.get('departmentId')?.value,
            startDate: this.formGroup.get('startDate')?.value,
            endDate: this.formGroup.get('endDate')?.value,
            questions: questionsWithNumbers
        };

        await this.formGeneratorService.save(survey).toPromise();

        await this.router.navigateByUrl('/forms-list');
    }
}
