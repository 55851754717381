import {NoticesComponent} from '../notices/notices.component';
import {Component, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Table} from 'primeng/table';
import {Contract, HttpContractsResponse} from 'src/model/contract';
import {AppStorageService} from 'src/services/app-storage.service';
import {ContractService} from 'src/services/contract.service';
import {JustificationService} from 'src/services/justification.service';
import {VacationService} from 'src/services/vacation.service';
import {ConfirmationService, ConfirmEventType, MessageService} from 'primeng/api';
import {Employee, HttpEmployeesResponse} from 'src/model/employee';
import {RegisterClockService} from 'src/services/register-clock.service';
import HttpRegistersClockResponse, {RegisterClock} from 'src/model/register-clock';
import {NotificationService} from 'src/services/notification.service';
import HttpNoticeResponse, {Notice} from 'src/model/notice';
import {DataView} from 'primeng/dataview';
import { CompanyService } from 'src/services/company.service';
import HttpCompanyResponse, { Company } from 'src/model/company';
import { EventEmitterService } from 'src/services/event-emitter.service';
import { forkJoin, map } from 'rxjs';
import { EmployeeService } from 'src/services/employee.service';
import {Router} from '@angular/router';
import { AbsenceService } from 'src/services/absence.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    providers: [DialogService, ConfirmationService, MessageService]
})
export class DashboardComponent implements OnInit {

    @ViewChild('dt') table?: Table;
    @ViewChild('dv') dataView?: DataView;

    public contracts: Contract[] = [];
    public vacations: any[] = [];
    public selectedContract: any[] = [];
    public clockRegisters: RegisterClock[] = [];
    public notices: Notice[] = [];
    public notice?: Notice;
    public clockResponse: any;
    public contractResponse: any;
    public contractsCardCount = 0;
    public vacationsCardCount:any = 0;
    public absenceCardCount:any = 0;
    public justificationCardCount = 0;
    public picture = './assets/icon/Avatar/user.png';
    public employee: Employee | undefined;
    public ref: DynamicDialogRef = new DynamicDialogRef();
    public loading = false;
    public loadingChart!: boolean;
    public showEmptyChart = false;
    public dateNow = Date.now();
    private company: Company | undefined;


    constructor(
        private appStorageService: AppStorageService,
        private contractService: ContractService,
        private vacationService: VacationService,
        private absenceService: AbsenceService,
        private justificationService: JustificationService,
        private employeeService: EmployeeService,
        private dialogService: DialogService,
        private messageService: MessageService,
        private registerClockService: RegisterClockService,
        private notificationService: NotificationService,
        private confirmationService: ConfirmationService,
        private companyService: CompanyService,
        private router: Router) {}

    async ngOnInit(): Promise<void> {
        await this.fetchData();
        await this.listTodayRegisterClock();
    }

    async fetchData(): Promise<void> {

        this.employee = await this.appStorageService.getEmployee();
        this.company = await this.appStorageService.getCompany();
        await this.updateCurrentPorductType();

        this.companyService.get(this.company?.id).subscribe(async (response: HttpCompanyResponse) => {

            const company: Company | undefined = response.data;

            if (company && company.status === Company.EXPIRED_STATUS){
                await this.router.navigate(['/checkout']);
            } else {
                await this.appStorageService.setCompany(response.data);
                EventEmitterService.get(EventEmitterService.COMPANY_EVENT_UPDATED).emit(response.data);
            }
        });

        await this.contractsCount();
        this.vacationsCardCount = await this.absenceService.countEmployeesInVacation();
        this.absenceCardCount = await this.absenceService.countEmployeesInAbsence();
        await this.justificationCount();
        await this.noticeListByCompany();
    }

    async updateCurrentPorductType() {
        const httpCompanyResponse: HttpCompanyResponse | undefined = await this.companyService.get(
            this.employee!.holderId).toPromise();
        await this.appStorageService.setProductType(httpCompanyResponse?.data?.productType!);
    }

    async contractsCount(): Promise<void> {
        this.loadingChart = true;

        await forkJoin(
            this.contractService.getContractsByCompany(this.employee?.companyId),
            this.employeeService.findAllByCompany(this.employee?.companyId!)
        )
            .pipe(
                map((response: any) => {
                    const httpContractResponse: HttpContractsResponse =
                        response[0];
                    const httpEmployeeResponse: HttpEmployeesResponse =
                        response[1];

                    httpContractResponse.data!.map((clock) => {
                        clock.employee = httpEmployeeResponse.data.find(
                            (el) => el.id === clock.employeeId
                        );
                    });
                    this.contractResponse = httpContractResponse;
                })
            )
            .toPromise();

        if (this.contractResponse.data) {
            this.contracts = this.contractResponse.data;
        }
        this.loadingChart = false;

        this.contractsCardCount = this.contracts.length || 0;
        if (this.contracts.length === 0) this.showEmptyChart = true;
    }

    async justificationCount(): Promise<void> {
        const startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD hh:mm');
        const endOfMonth = moment().clone().endOf('month').format('YYYY-MM-DD hh:mm');

        const response: any = await this.justificationService.getJustificationByCompany(
            this.employee?.companyId, startOfMonth, endOfMonth
        ).toPromise();

        const result = await response.data;
        this.justificationCardCount = result.length;
    }

    async listTodayRegisterClock(): Promise<void> {
        try {
            const today = moment();
            const convertStartDataToSearch = moment(today, 'DD/MM/YYYY').startOf('hour').format(
                'YYYY-MM-DD 00:00:00'
            );
            const convertEndDataToSearch = moment(today, 'DD/MM/YYYY').endOf('hour').format(
                'YYYY-MM-DD 23:59:59'
            );

            const employee = await this.appStorageService.getEmployee();

            // const response = await this.registerClockService
            //     .listByCompany(employee.companyId as string, convertStartDataToSearch, convertEndDataToSearch)
            //     .toPromise() || null;

            await forkJoin(
                this.registerClockService.listByCompany(employee.companyId as string, convertStartDataToSearch, convertEndDataToSearch),
                this.employeeService.findAllByCompany(employee.companyId as string)).pipe(
                    map((response: any) => {
                        const httpClockResponse: HttpRegistersClockResponse = response[0];
                        const httpEmployeeResponse: any = response[1];

                        httpClockResponse.data!.map((clock) => {
                            clock.employee = httpEmployeeResponse.data.find((el: any) => el.id === clock.employeeId);
                        });
                        this.clockResponse = httpClockResponse;
                    })
            ).toPromise();

            this.clockResponse.data?.map((clocks: RegisterClock) => {
                this.clockRegisters.push(clocks);
            });
        } catch (error) {
            console.log(error);
        }
    }

    async noticeListByCompany(): Promise<void> {
        try {
            this.employee = await this.appStorageService.getEmployee();

            const path =  this.employee.path;
            const companyId = path!.split('#', 1);

            this.notificationService.listByCompany(
                companyId[0]
            ).subscribe((data: any ) => {
                this.loading = true;

                if (data.data.length > 0) {
                    this.notices = data.data.sort((a: any, b: any) =>
                    // tslint:disable-next-line:no-non-null-assertion
                    a.createdAt! > b.createdAt! ? -1 : a.createdAt! < b.createdAt! ? 1 : 0);

                    this.notices.map((notice: Notice) => {
                        if (notice.priority === 'LOW') {
                            notice.priority = '1-LOW';
                        }
                        if (notice.priority === 'MEDIUM') {
                            notice.priority = '2-MEDIUM';
                        }
                        if (notice.priority === 'HIGH') {
                            notice.priority = '3-HIGH';
                        }
                        return notice;

                    });
                } else {
                    data = [];
                }
            });
            this.loading = false;
        } catch (error) {
            this.loading = false;
        }
    }

    public getPriorityId(notice: Notice): Notice {
        switch (notice.priority) {
            case 'HIGH':
                notice.priorityId = 1;
                break;
            case 'MEDIUM':
                notice.priorityId = 2;
                break;
            case 'LOW':
                notice.priorityId = 3;
                break;
            default:
                break;
        }

        return notice;
    }

    public onCreateNotice(): void {
        this.ref = this.dialogService.open(NoticesComponent, {
            header: 'Novo Aviso',
            width: '553px',
            contentStyle: {'max-height': '500px', overflow: 'auto'},
            baseZIndex: 10000,
            dismissableMask: true,
            data: {values: ''}
        });

        this.ref.onClose.subscribe(async (message) => {
            await this.noticeListByCompany();
        });
    }

    public onUpdate(notice: Notice): void {
        const newNotice: any = {
            id: notice.id,
            companyId: notice.companyId,
            createdById: notice.createdById,
            path: notice.path,
            notice: notice.notice,
            priority: notice.priority?.substring(2),
        };
        this.notice = newNotice;

        this.ref = this.dialogService.open(NoticesComponent, {
            header: 'Editar Aviso',
            width: '553px',
            contentStyle: {'max-height': '500px', overflow: 'auto'},
            baseZIndex: 10000,
            dismissableMask: true,
            data: {values: this.notice}
        });

        this.ref.onClose.subscribe(async (message) => {
            await this.noticeListByCompany();
        });
    }

    public async onDeleteNotice(id: string): Promise<void> {
        this.confirmationService.confirm({
            message: 'Tem certeza de que deseja excluir esse aviso?',
            header: 'Excluir Aviso',
            // icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.notificationService.deleteNotice(id).subscribe(
                    (response: any) => {
                        if (response.success) {
                            this.noticeListByCompany();
                        }
                    },
                    (err) => {
                        // this.messageService.add({severity: 'error', summary: 'Ocorreu um erro', detail: 'Ocorreu um erro interno'});
                    }
                );
            },
            reject: (type: any) => {
                switch (type) {
                    case ConfirmEventType.REJECT:
                        // this.messageService.add({severity: 'error', summary: 'Não confirmado', detail: 'Aviso não deletado'});
                        break;
                    case ConfirmEventType.CANCEL:
                        //  this.messageService.add({severity: 'warn', summary: 'Cancelado', detail: 'Ação cancelada'});
                        break;
                }
            }
        });
    }

}
