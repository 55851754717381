<p-toast></p-toast>
<p-dialog header="Fotos" [(visible)]="showPhotosModal" [style]="{width: '400px'}">
    <div class="grid align-items-center justify-content-center mt-1">
        <img class="img-fluid" *ngIf="currentImage" [src]="currentImage" style="border-radius: 15px;" />

        <div class="col-12">
            <p-paginator [first]="first" [rows]="1" [totalRecords]="totalRecords" (onPageChange)="onPageChange($event)"
                [showJumpToPageDropdown]="false" [showFirstLastIcon]="false" [showPageLinks]="true" [pageLinkSize]="1"
                currentPageReportTemplate="{currentPage}" styleClass="imagePaginator">
            </p-paginator>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <div class="text-right">
            <button type="button" class="btn btn-link mr-2" (click)="onBtnCloseModal()">Cancelar</button>
            <button type="submit" class="btn btn-primary btn-default" (click)="downloadAllImages()">Baixar</button>
        </div>
    </ng-template>
</p-dialog>

<p-dialog header="Formulário" [(visible)]="showFormViwerModal" [style]="{width: '400px'}">
    <div class="grid align-items-center justify-content-start mt-1" style="font-size: 14px">
        <div *ngIf="formViwer">
            <p class="font-weight-light">{{ formViwer.name }}</p>

            <div *ngFor="let question of formViwer.questions; let i = index">
                <div class="font-weight-normal">
                        <span>
                            {{question.number}}. {{ question.question }}
                        </span>

                    <p class="font-weight-light" *ngIf="question.answer">R: {{ question.answer.answer }}</p>
                </div>
            </div>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <div class="text-right">
            <button type="button" class="btn btn-link mr-2" (click)="hideFormAnswers()">Cancelar</button>
            <button type="submit" class="btn btn-primary btn-default" (click)="saveFormAnswersAsTxt()">Baixar</button>
        </div>
    </ng-template>
</p-dialog>


<div>
    <app-searchbar-employee
        (searchEvent)="search($event)"
        (cleanForm)="onCleanForm()"
        #searchBar>
    </app-searchbar-employee>

    <div class="card shadow">
        <div class="card-body">
            <div class="table-responsive">
                <p-table #dt
                         [value]="clockRegisters"
                         dataKey="id"
                         styleClass="p-datatable-employee"
                         [rowHover]="true"
                         [rows]="10"
                         [showCurrentPageReport]="true"
                         [rowsPerPageOptions]="[10,25,50]"
                         [paginator]="true"
                         [filterDelay]="0"
                         sortField="recordTime" [sortOrder]="-1"
                         [globalFilterFields]="['employee.name','type', 'recordDate', 'recordTime']">

                    <ng-template pTemplate="caption">
                        <div class="table-header">
                            <!-- <button type="button" class="btn btn-sm btn-link ml-3" (click)="searchBar.onCleanForm()">
                                Limpar filtro
                            </button> -->
                            <button type="button" class="btn btn-sm btn-outline-primary ml-3" (click)="generatePdf()"
                                    [disabled]="clockRegisters.length === 0">
                                <div class="d-flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-0" fill="none"
                                         viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/>
                                    </svg>
                                    <span>Baixar relatório</span>
                                </div>
                            </button>
                        </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                        <tr *ngIf="clockRegisters?.length !== 0">
                            <th pSortableColumn="type">Status
                                <p-sortIcon field="type"></p-sortIcon>
                            </th>
                            <th pSortableColumn="employee.name">Nome
                                <p-sortIcon field="name"></p-sortIcon>
                            </th>
                            <th pSortableColumn="recordDate">Data
                                <p-sortIcon field="recordDate"></p-sortIcon>
                            </th>
                            <th pSortableColumn="recordTime">Horário
                                <p-sortIcon field="recordTime"></p-sortIcon>
                            </th>
                            <th>Endereço</th>
                            <th>Coordenadas</th>
                            <th>
                                <div class="flex justify-conten-center align-items-center">
                                    <label class="mb-0 mr-2" for="attachment">Anexo</label>
                                    <p-checkbox
                                        name="attachment"
                                        [pTooltip]="tooltipText"
                                        tooltipPosition="top"
                                        tooltipStyleClass="shadow"
                                        (onChange)="onCheckboxChange($event)"
                                        [binary]="true">
                                    </p-checkbox>
                                </div>
                            </th>
                        </tr>
                        <tr *ngIf="haveClockRegisters">
                            <th colspan="6">
                                <div class="d-flex justify-content-center text-muted">
                                    <h6>Nenhum Registro de Ponto encontrado</h6>
                                </div>
                            </th>
                        </tr>
                        <tr *ngIf="loading">
                            <th colspan="6">
                                <div
                                    class="loading-box d-flex justify-content-center align-items-center text-muted p-3">
                                    <span class="spinner-grow spinner-grow-sm m-2" role="status"
                                          aria-hidden="true"></span>
                                    Aguarde estamos carregando os dados...
                                </div>
                            </th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-clock let-i>
                        <tr class="p-selectable-row">
                            <td>
                                <span *ngIf="clock.type === 'E'" [class]="'badge badge-green'">Entrada</span>
                                <span *ngIf="clock.type === 'F'" [class]="'badge badge-orange'">Ausente</span>
                                <span *ngIf="clock.type === 'S'" [class]="'badge badge-gray'">Saída</span>
                            </td>
                            <td>{{ clock.employee.name }}</td>
                            <td>{{ clock.recordDate | date:'dd/MM/yyyy' }}</td>
                            <td *ngIf="clock.type !== 'F' else default">{{ clock.recordTime }}</td>
                            <ng-template #default>
                                <td class="text-center">-</td>
                            </ng-template>
                            <td *ngIf="clock.type !== 'F' else default">{{ clock.address }}</td>
                            <ng-template #default>
                                <td class="text-center">-</td>
                            </ng-template>
                            <td *ngIf="clock.type !== 'F' else default">
                                <a href="https://maps.google.com/?q={{ clock.latitude }},{{ clock.longitude }}" target="_blank"
                                   class="btn btn-link"><img class="history-map-icon" src="./assets/icon/Map/map.svg"/>
                                </a>
                            </td>
                            <ng-template #default>
                                <td class="text-center">-</td>
                            </ng-template>
                            <td class="text-center text-primary w-1">
                                <span *ngIf="clock.hasAttachment">
                                    <i class="bi bi-eye icon cursor-pointer" (click)="onBtnShowPhotos(clock.id)"  pTooltip="Ver foto(s)" tooltipPosition="top" tooltipStyleClass="shadow" style="font-size: 1.3rem;"></i>
                                </span>
                            </td>
                            <td class="text-center text-primary w-1">
                                <span *ngIf="clock.hasSurvey">
                                    <i class="bi bi-file-text icon cursor-pointer" (click)="showFormAnswers(clock.id)"  pTooltip="Ver formulário" tooltipPosition="top" tooltipStyleClass="shadow" style="font-size: 1.3rem;"></i>
                                </span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>
