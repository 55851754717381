import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {NavigationEnd, NavigationExtras, Router} from '@angular/router';

import * as moment from 'moment';
import {MessageService} from 'primeng/api';
import {Table} from 'primeng/table';
import {Observable, timer} from 'rxjs';
import {map} from 'rxjs/operators';
import {ChronometeComponent} from 'src/components/chronomete/chronomete.component';
import {HttpRegisterClockResponse} from 'src/model/clock-adjustment';
import {Company} from 'src/model/company';
import {Contract, ContractPeriod} from 'src/model/contract';
import {Employee} from 'src/model/employee';
import HttpRegistersClockResponse, {RegisterClock} from 'src/model/register-clock';
import {AppStorageService} from 'src/services/app-storage.service';
import {ClockAjustmentService} from 'src/services/clock-ajustment.service';
import {CompanyService} from 'src/services/company.service';
import {ContractService} from 'src/services/contract.service';
import {EmployeeService} from 'src/services/employee.service';
import {RegisterClockService} from 'src/services/register-clock.service';
import JourneyHelp from '../shared/journey-helper';
import { AbsenceService } from 'src/services/absence.service';


@Component({
    selector: 'app-employee-register-time',
    templateUrl: './employee-register-time.component.html',
    styleUrls: ['./employee-register-time.component.scss'],
    providers: [MessageService]
})
export class EmployeeRegisterTimeComponent implements OnInit, OnDestroy {

    constructor(
        private appStorageService: AppStorageService,
        private employeeService: EmployeeService,
        private companyService: CompanyService,
        private messageService: MessageService,
        private registerClockService: RegisterClockService,
        private contractService: ContractService,
        private formBuilder: FormBuilder,
        private clockAdjustmentService: ClockAjustmentService,
        private absenceService: AbsenceService,
        private router: Router
    ) {
        this.form = this.formBuilder.group({
            startTimeFirstPeriod: [''],
            endTimeFirstPeriod: [''],
            startTimeSecondPeriod: [''],
            endTimeSecondPeriod: [''],
            recordDate: [''],
        });

        this.getLocation();
    }

    @ViewChild('dt') table?: Table;
    @ViewChild('chronometer', {static: false}) chronometer: ChronometeComponent | undefined;

    public hours = 0;
    public minutes = 0;
    public seconds = 0;
    public workDayTime = 8;
    public currentDateTime: Observable<Date> | undefined;
    public debugMessage = '';
    public loadMessage = '';
    public workRule = '';
    public registeredAddress = false;
    public modalPosition = '';
    public latitude!: any;
    public longitude!: any;
    public onAbsence: any;

    public todayStartTimeFirstPeriod = '';
    public todayEndTimeFirstPeriod = '';
    public todayStartTimeSecondPeriod = '';
    public todayEndTimeSecondPeriod = '';
    public todayIntervalPeriods = '';

    public sumOfPeriods = 0;
    public loading: boolean | undefined;
    public loadingTable: boolean | undefined;
    public groupResult: any;
    public clockAdjustmentTemp: any;
    public clockRegisters: RegisterClock[] = [];
    public firstPeriod: any[] = [];
    public secondPeriod: any[] = [];
    public form: FormGroup;

    public homeOffice = false;
    public started = false;
    public showClockAjustment = false;
    public showClockAjustmentSuccess = false;

    public company: Company | undefined;
    public contractLocal: any;
    public clockAdjustmentDialogInfo: any;

    private normalizedContractResponse: any;
    private employee: Employee | undefined;
    private iniStop = 12;
    private endStop = 14;

    static normalizeContractResponse(contract: Contract | null) {

        const now = moment();
        const config: any = Object.assign({}, contract);

        const startTimeFirstPeriod = moment(config.startTimeFirstPeriod, 'HH:mm');
        const endTimeFirstPeriod = moment(config.endTimeFirstPeriod, 'HH:mm');
        const startTimeSecondPeriod = moment(config.startTimeSecondPeriod, 'HH:mm');
        const endTimeSecondPeriod = moment(config.endTimeSecondPeriod, 'HH:mm');

        config.startTimeFirstPeriod = now.clone().set({
            hours: startTimeFirstPeriod.get('hour'),
            minutes: startTimeFirstPeriod.get('minute'),
            seconds: startTimeFirstPeriod.get('second')
        });

        config.endTimeFirstPeriod = now.clone().set({
            hours: endTimeFirstPeriod.get('hour'),
            minutes: endTimeFirstPeriod.get('minute'),
            seconds: endTimeFirstPeriod.get('second')
        });

        config.startTimeSecondPeriod = now.clone().set({
            hours: startTimeSecondPeriod.get('hour'),
            minutes: startTimeSecondPeriod.get('minute'),
            seconds: startTimeSecondPeriod.get('second')
        });

        config.endTimeSecondPeriod = now.clone().set({
            hours: endTimeSecondPeriod.get('hour'),
            minutes: endTimeSecondPeriod.get('minute'),
            seconds: endTimeSecondPeriod.get('second')
        });

        return config;
    }

    public getLocation(): void {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
                console.warn('Alphatime position log', {'lat: ': this.latitude, 'lon: ': this.longitude});
            });
        } else {
            this.messageService.add({
                severity: 'warn',
                detail: 'Desculpe',
                summary: 'A geolocalização não é suportada por este navegador!',
            });
        }
    }

    async ngOnInit(): Promise<void> {
        console.log('ngOnInit');
        await this.loadRegime();
        await this.loadAddress();
        await this.vacationStatus();

        this.groupResult = [];
        this.loadingTable = true;
        await this.selectCompany();
        await this.getPeriodsOfDay();
        await this.findEmployeeByDates();
        this.currentDateTime = timer(0, 1000).pipe(
            map(() => {
                return new Date();
            })
        );
    }

    async ngOnDestroy(): Promise<void> {
        await this.lastChronometerTimer();
    }

    public async loadRegime(): Promise<void> {
        this.contractLocal = await this.appStorageService.getCurrentContract();

        if (this.contractLocal.homeOffice === true) {
            this.workRule = 'Home Office';
        } else if (this.contractLocal.flexibleJourney === true) {
            this.workRule = 'Jornada Híbrida';
        } else if (this.contractLocal.headOffice === true) {
            this.workRule = 'Padrão';
        } else if (this.contractLocal.skipAllValidation === true) {
            this.workRule = 'Ponto Livre';
        } else {
            this.workRule = '';
        }
    }

    public async loadAddress() {
        if (this.contractLocal.employeezipcode !== undefined) {
            this.registeredAddress = true;
        } else {
            this.registeredAddress = false;
        }
    }

    async goToConfigureEmployee(): Promise<void> {
        const employeeLocal: Employee = await this.appStorageService.getEmployee();
        const employee: any = await this.employeeService.findByEmail(employeeLocal.email!).toPromise();
        const contract = await this.contractService.getContractsByEmployee(employeeLocal.id).toPromise();
        const company: any = await this.companyService.get(employeeLocal.companyId).toPromise();

        const navigationExtras: NavigationExtras = {
            state: {
                employee: employee.data,
                contract: contract?.data[0],
                company: company.data
            }
        };

        await this.router.navigate(['/configure-employee'], navigationExtras);
    }

    public async selectCompany(): Promise<void> {
        this.employee = await this.appStorageService.getEmployee();
        if (this.employee) {
            const {id, companyId} = this.employee;
            const response: any | undefined = await this.contractService.getContractsByEmployeeAndCompany(id!, companyId!).toPromise();
            const company: any | undefined = await this.companyService.get(companyId).toPromise();
            if (response!.success) {
                const contract: Contract = await Object.assign(response!.data[0]!);
                await this.appStorageService.setCurrentContract(contract);
                this.company = company;

                await this.loadContractInformation(contract);
            }
        }
    }

    /**
     * Clock Event from chronometer
     *
     */
    public async clockEvent(evt: any) {
        if (evt && this.started) {

            this.hours = evt.hours;
            this.minutes = evt.minutes;
            this.seconds = evt.seconds;

            if (evt.hours >= this.normalizedContractResponse.endTimeSecondPeriod.get('hour') + 2) {
                await this.stopRegisterClock(this.normalizedContractResponse.endTimeSecondPeriod.clone().add(2, 'hours'));
            }
        }
    }

    /**
     * Start chronometer
     *
     */
    public async startRegisterClock() {
        console.warn('Alphatime start position log', {'lat: ': this.latitude, 'lon: ': this.longitude});
        try {

            this.loadMessage = 'Registrando ponto...';
            this.loading = true;
            const now = moment();
            const contract: any = await this.getCachedContract();
            const currentContract = await this.appStorageService.getCurrentContract();
            const period = await this.getPeriod();

            const company: any | undefined = this.company;
            const employee = await this.appStorageService.getEmployee();

            const latitude = this.latitude ? this.latitude.toString() : '0';
            const longitude = this.longitude ? this.longitude.toString() : '0';
            const distanceFromOffice = this.getDistanceFromPosition(this.latitude, this.longitude, contract.branchAddress ? contract : company);

            this.debugMessage = `filial: ${contract.branchAddress} current: ${latitude},${longitude} distance: ${distanceFromOffice}`;

            const today = moment().isoWeekday();
            const journeyJson: any =  JourneyHelp.getJourneyJson(currentContract?.journey);

            const startTimeFirstPeriod = moment(journeyJson[today][0], 'HH:mm');
            const endTimeFirstPeriod = moment(journeyJson[today][1], 'HH:mm');
            const startTimeSecondPeriod = moment(journeyJson[today][2], 'HH:mm');
            const endTimeSecondPeriod = moment(journeyJson[today][3], 'HH:mm');

            if (contract.skipAllValidation === true || contract.skipAllValidation === undefined) {
                const todaySchedule = journeyJson[today];
                const isFalse = todaySchedule[todaySchedule.length - 1] === 'false';

                if (isFalse) {
                    this.loadMessage = '';
                    this.loading = false;
                    this.messageService.add({
                        severity: 'warn',
                        summary: 'Desculpe',
                        detail: 'Hoje não tem jornada de trabalho!',
                    });
                    return;
                }
              } else if (contract.skipAllValidation === false || contract.skipAllValidation === undefined) {
                if (now.hour() >= endTimeSecondPeriod.get('hour') + 2) {
                    this.loadMessage = '';
                    this.loading = false;
                    this.messageService.add({
                        severity: 'warn',
                        summary: 'Desculpe',
                        detail: 'Inicio da Jornada esta fora do horário máximo permitido por Lei!',
                    });
                    return;
                }

                const todaySchedule = journeyJson[today];
                const isFalse = todaySchedule[todaySchedule.length - 1] === 'false';

                if (isFalse) {
                    this.loadMessage = '';
                    this.loading = false;
                    this.messageService.add({
                        severity: 'warn',
                        summary: 'Desculpe',
                        detail: 'Hoje não tem jornada de trabalho!',
                    });
                    return;
                }

                if (period === 'fora_da_jornada') {
                    this.loadMessage = '';
                    this.loading = false;
                    this.messageService.add({
                        severity: 'warn',
                        summary: 'Desculpe',
                        detail: 'Você está fora da jornada!',
                    });
                    return;
                }

                if (period === 'primeiro_turno') {
                    if (!moment().isBetween(startTimeFirstPeriod!.clone().subtract(10, 'minutes'), endTimeFirstPeriod)) {
                        this.loadMessage = '';
                        this.loading = false;
                        this.messageService.add({
                            severity: 'warn',
                            summary: 'Desculpe',
                            detail: 'Inicio da Jornada só é permitido iniciar apenas 10 minutos antes do incio da primeira Jornada!',
                        });
                        return;
                    }
                }

                if (period === 'segundo_turno' || period === 'intervalo') {
                    if (!moment().isBetween(startTimeSecondPeriod!.clone().subtract(10, 'minutes'), endTimeSecondPeriod)) {
                        this.loadMessage = '';
                        this.loading = false;
                        this.messageService.add({
                            severity: 'warn',
                            summary: 'Desculpe',
                            detail: 'Inicio da Jornada só é permitido inciar apenas 10 minutos antes do incio da segunda Jornada!',
                        });
                        return;
                    }
                }
              }

            const lastRegisterClock: RegisterClock = await this.getLastRegisterClock();

            if (lastRegisterClock === undefined) {
                await this.appStorageService.setLastChronometerTimer({
                    hours: 0,
                    minutes: 0,
                    seconds: 0
                }).then(() => {
                    this.resetTimer();

                });
            }

            // this.appStorageService.setLastChronometerTimer({
            //     hours: 0,
            //     minutes: 0,
            //     seconds: 0
            // }).then(() => {
            //     this.resetTimer();
            //     this.chronometer!.start();
            // });
            this.chronometer!.start();

            await this.appStorageService.removeLastRegisterStopClock();

            const data: RegisterClock = {
                contractId: contract.id,
                departmentId: contract.departmentId,
                companyId: company.data.id,
                path: company.data.path,
                employeeId: employee!.id,
                allowSourceFrom: 'WEB',
                currentContract: JSON.stringify(currentContract),
                recordDate: now.format('YYYY-MM-DD'),
                recordTime: now.format('HH:mm:ss'),
                type: 'E',
                recordDateTime: `${now.format('YYYY-MM-DD')} ${now.format('HH:mm:ss')}`,
                latitude,
                longitude
            };

            this.loadMessage = '';
            this.loading = false;

            await this.register(data);
        } catch (error) {
            console.log('data :::: ', error);
        } finally {
            this.loadMessage = '';
            this.loading = false;
        }
    }

    private async vacationStatus() {
        const employeeInAbsence = await this.absenceService.isEmployeeInAbsence();
        this.onAbsence = employeeInAbsence;
    }

    public async getPeriodsOfDay() {
        this.employee = await this.appStorageService.getEmployee();

        const startDate = moment().clone().startOf('days').subtract(1, 'days').toISOString();
        const endDate = moment().clone().endOf('days').toISOString();

        const config: ContractPeriod = await this.getCachedContract();

        const today = moment().isoWeekday();
        const journeyJson: any =  JourneyHelp.getJourneyJson(config?.journey);

        const startTimeFirstPeriod = moment(journeyJson[today][0], 'HH:mm');
        const endTimeFirstPeriod = moment(journeyJson[today][1], 'HH:mm');
        const startTimeSecondPeriod = moment(journeyJson[today][2], 'HH:mm');
        const endTimeSecondPeriod = moment(journeyJson[today][3], 'HH:mm');

        const response = await this.registerClockService
            .getClockRegistersByEmployeeAndDates(this.employee.id as string, startDate, endDate)
            .toPromise();

        // @ts-ignore
        this.clockRegisters = response.data;

        this.clockRegisters.map((clocks: RegisterClock) => {
            if (
                moment(clocks.recordTime, 'HH:mm').isBefore(startTimeFirstPeriod) &&
                moment(clocks.recordTime, 'HH:mm').isBefore(startTimeSecondPeriod)) {
                this.firstPeriod.push(moment(clocks.recordTime, 'HH:mm').format('HH:mm'));
            }
            if (moment(clocks.recordTime, 'HH:mm').isBetween(startTimeFirstPeriod, endTimeFirstPeriod)) {
                this.firstPeriod.push(moment(clocks.recordTime, 'HH:mm').format('HH:mm'));
            }

            if (
                moment(clocks.recordTime, 'HH:mm').isAfter(startTimeFirstPeriod) &&
                moment(clocks.recordTime, 'HH:mm').isBefore(startTimeSecondPeriod) &&
                moment(clocks.recordTime, 'HH:mm').isBefore(endTimeSecondPeriod)) {
                this.secondPeriod.push(moment(clocks.recordTime, 'HH:mm').format('HH:mm'));
            }
            if (moment(clocks.recordTime, 'HH:mm').isBetween(startTimeSecondPeriod, endTimeSecondPeriod)) {
                this.secondPeriod.push(moment(clocks.recordTime, 'HH:mm').format('HH:mm'));
            }
        });

        this.todayStartTimeFirstPeriod = this.firstPeriod[0];

        if (this.firstPeriod.length > 1) {
            this.todayEndTimeFirstPeriod = this.firstPeriod[this.firstPeriod.length - 1];
        }

        if (this.secondPeriod[0] !== undefined) {
            if (this.firstPeriod[this.firstPeriod.length - 1] !== undefined) {
                this.todayIntervalPeriods = `${this.firstPeriod[this.firstPeriod.length - 1]} - ${this.secondPeriod[0]}`;
            } else {
                this.todayIntervalPeriods = `${this.secondPeriod[0]}`;
            }
        }

        this.todayStartTimeSecondPeriod = this.secondPeriod[0];

        if (this.secondPeriod.length > 1) {
            this.todayEndTimeSecondPeriod = this.secondPeriod[this.secondPeriod.length - 1];
        }

        await this.calculateAmountOfHours(response!.data);
    }

    public async calculateAmountOfHours(contractParam: any) {
        let total = 0;
        let diff = 0;
        let diffDuration = 0;

        contractParam.forEach((el: any, idx: number) => {
            if (idx % 2 != 0) {
                if (el.currentContract) {
                    const currentContract = JSON.parse(contractParam[idx - 1].currentContract);
                    const today = moment().isoWeekday();
                    const journeyJson: any =  JourneyHelp.getJourneyJson(currentContract?.journey);

                    const startTimeFirstPeriod = moment(journeyJson[today][0], 'HH:mm');
                    const endTimeFirstPeriod = moment(journeyJson[today][1], 'HH:mm');
                    const startTimeSecondPeriod = moment(journeyJson[today][2], 'HH:mm');
                    const endTimeSecondPeriod = moment(journeyJson[today][3], 'HH:mm');

                    // const startTimeFirstPeriod = moment(currentContract.startTimeFirstPeriod);
                    // const endTimeFirstPeriod = moment(currentContract.endTimeFirstPeriod);
                    // const startTimeSecondPeriod = moment(currentContract.startTimeSecondPeriod);
                    // const endTimeSecondPeriod = moment(currentContract.endTimeSecondPeriod);

                    const journey: any = moment.duration(endTimeFirstPeriod.diff(startTimeFirstPeriod)).asHours() +
                        moment.duration(endTimeSecondPeriod.diff(startTimeSecondPeriod)).asHours();

                    const prev = moment((contractParam[idx - 1] as any).recordDateTime, 'YYYY-MM-DD HH:mm:ss');
                    const curr = moment(el.recordDateTime, 'YYYY-MM-DD HH:mm:ss');

                    const duration = moment.duration(curr.diff(prev));
                    total = total + (duration.asHours());
                    diff = 100 / journey;
                    diffDuration = duration.asHours();
                }
            }
        });

        const amountOfHours = total;

        this.sumOfPeriods = diff * amountOfHours;
    }

    public async confirmStopClock() {
        await this.stopRegisterClock();
    }

    /**
     *  Stop chronometer
     */
    public async stopRegisterClock(time?: moment.Moment) {
        this.loadMessage = 'Registrando ponto...';
        this.loading = true;

        const contract = await this.appStorageService.getCurrentContract();
        const employee = await this.appStorageService.getEmployee();
        // const employee: Employee | undefined = contract!.employee;
        const company: any | undefined = this.company;

        const hora = time ? time : moment();
        const data: RegisterClock = {
            contractId: contract!.id,
            companyId: company.data!.id,
            path: company.data.path,
            departmentId: contract!.departmentId,
            employeeId: employee!.id,
            allowSourceFrom: 'WEB',
            currentContract: JSON.stringify(contract),
            recordDate: hora.format('YYYY-MM-DD'),
            recordTime: hora.format('HH:mm:ss'),
            type: 'S',
            recordDateTime: `${hora.format('YYYY-MM-DD')} ${hora.format('HH:mm:ss')}`,
            latitude: this.latitude ? this.latitude.toString() : '0',
            longitude: this.longitude ? this.longitude.toString() : '0',
        };

        await this.lastChronometerTimer();

        await this.register(data);
    }

    async findEmployeeByDates(): Promise<void> {
        this.loadingTable = true;
        this.employee = await this.appStorageService.getEmployee();

        const startDate = moment().clone().startOf('month').subtract(1, 'days').toISOString();
        const endDate = moment().clone().startOf('day').subtract(1, 'days').toISOString();

        const response = await this.registerClockService
            .getClockRegistersByEmployeeAndDates(this.employee.id as string, startDate, endDate)
            .toPromise();

        await this.groupEmployeeWithContract(response?.data);
    }

    async groupEmployeeWithContract(response: any): Promise<void> {
        let clocks: any[] = [];
        const registers: [] = [];

        const filterValids = await response.filter((clocks: any) => {
            return clocks.status !== 'INVALID';
        });

        const sorted = filterValids.sort((a: any, b: any) => {
            if (a.employeeId === b.employeeId) {
                return a.recordDateTime < b.recordDateTime ? -1 : 1;
            } else {
                return a.employeeId < b.employeeId ? -1 : 1;
            }
        });

        // @ts-ignore
        await sorted.forEach((el, idx) => {
            el.address = el.address ? el.address.replace('Unnamed Road', 'Rua Desconhecida') : 'Rua Desconhecida';
            el.recordTimeStr = el.recordDateTime;
            el.recordTime = moment(el.recordDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
            el.total = '00:00';
            if (idx > 0) {
                if (sorted[idx - 1].type === 'E' && el.type === 'S') {
                    const inputclock = moment(sorted[idx - 1].recordDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
                    const exitClock = moment(el.recordDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
                    const result = {entrada: inputclock, saida: exitClock};
                    el.total = this.minsToStr(this.strToMins(result.saida) - this.strToMins(result.entrada));
                    el.recordTime = result;
                }
            }

            // @ts-ignore
            registers.push(el);
        });

        registers.map((results: any) => {
            if (results.type === 'S') {
                clocks.push({
                    employeeId: results.id,
                    picture: results.picture,
                    name: results.name,
                    address: results.address,
                    recordDate: results.recordDate,
                    recordTime: results.recordTime,
                    recordDateTime: results.recordDateTime,
                    total: results.total,
                    type: results.type,
                    clockAdjustmentId: results.clockAdjustmentId,
                    status: results.status,
                });
            }
        });

        const contract = await this.appStorageService.getCurrentContract();

        const today = moment().isoWeekday();
        const journeyJson: any =  JourneyHelp.getJourneyJson(contract?.journey);

        const stfp = JSON.stringify(moment(journeyJson[today][0], 'HH:mm'));
        const etfp = JSON.stringify(moment(journeyJson[today][1], 'HH:mm'));
        const stsp = JSON.stringify(moment(journeyJson[today][2], 'HH:mm'));
        const etsp = JSON.stringify(moment(journeyJson[today][3], 'HH:mm'));

        const firstPeriod = this.dateDifference(
            moment(etfp, 'HH:mm'),
            moment(stfp, 'HH:mm')
        );
        const secondPeriod = this.dateDifference(
            moment(etsp, 'HH:mm'),
            moment(stsp, 'HH:mm')
        );
        const cargaHoraria = firstPeriod + secondPeriod;

        clocks = await clocks.reduce((clock, curr) => {

            const total = curr.total;

            clock.push(
                {
                    ...curr,
                    journey: cargaHoraria,
                    recordTime: {...curr.recordTime, total: curr.total},
                    address: curr.address,
                    hoursBalance: this.getBalance(curr.total, moment(cargaHoraria, 'HH').format('HH:mm')),
                }
            );
            return clock;
        }, []);

        await this.getPeriodsMonth(clocks);
    }

    public async getPeriodsMonth(value: any) {
        if (value.length > 0) {
            const grouped = value.reduce((accumulator: any, element: any) => {
                const key = element.recordDate + element.name; // group by recordDate and name, to generate unique key
                accumulator[key] = accumulator[key] || []; // if is not already a grouping then empty array
                accumulator[key].push(element); // else add the element to group
                return accumulator; // and finally return the accumulator for the next iteration
            }, {});

            const finalResult: any[] = [];
            const keys = Object.keys(grouped);
            keys.forEach((key: any, index: any) => {
                finalResult.push({key, value: grouped[key]});
            });

            const result: any[] = [];

            finalResult.forEach(data => {
                const temp = {
                    id: data.value[0].employeeId,
                    recordDate: data.value[0].recordDate,
                    picture: data.value[0].picture,
                    name: data.value[0].name,
                    address: data.value[0].address,
                    journey: data.value[0].journey,
                    recordTime: data.value.map((infor: any) => (
                        infor.recordTime
                    )),
                    totalHours: this.totalClock(data.value.map((infor: any) => infor.total)),
                    clockAdjustmentId: data.value[0].clockAdjustmentId,
                    status: data.value[0].status,
                };

                result.push(
                    {
                        ...temp,
                        hoursBalance: temp.totalHours < moment(temp.journey, 'HH:mm').format('HH:mm')
                            ? `-${moment(this.getBalance(temp.totalHours, moment(temp.journey, 'HH:mm').format('HH:mm')), 'HH:mm').format('HH:mm')}`
                            : `+${moment(this.getBalance(temp.totalHours, moment(temp.journey, 'HH:mm').format('HH:mm')), 'HH:mm').format('HH:mm')}`

                    }
                );
            });

            const filterResult = result.filter((r, i) => {
                return r.hoursBalance || r.status === 'WAITING_REVIEW';
            });

            this.groupResult = [filterResult[filterResult.length - 1]];

            this.loadingTable = false;
        } else {
            this.groupResult = [];
            this.loadingTable = false;
        }
    }

    private dateDifference(startDate: any, endDate: any): number {
        return moment(startDate).diff(moment(endDate), 'hours');
    }

    private getBalance(total: any, journey: any): string {
        const totalHour = moment(total, 'HH:mm');
        const jornada = moment(journey, 'HH:mm');
        let duration: any;

        if (totalHour > jornada) {
            duration = moment.duration(totalHour.diff(jornada));
        } else {
            duration = moment.duration(jornada.diff(totalHour));
        }

        return moment.utc(duration.as('milliseconds')).format('HH:mm');
    }

    private strToMins(t: any): number {
        const s = t.split(':');
        return Number(s[0]) * 60 + Number(s[1]);
    }

    private minsToStr(t: any): string {
        return Math.trunc(t / 60) + ':' + ('00' + t % 60).slice(-2);
    }

    totalClock(timesClock: any): string {
        let hours = 0;
        let minutes = 0;

        timesClock.forEach((time: any) => {
            const split = time.split(':');
            // tslint:disable-next-line:radix
            hours += parseInt(split[0]);
            // tslint:disable-next-line:radix
            minutes += parseInt(split[1]);
        });

        const formattedNumber = (num: any) => ('0' + num).slice(-2);
        hours += Math.floor(minutes / 60);
        minutes = minutes % 60;

        return formattedNumber(hours) + ':' + formattedNumber(minutes);
    }

    private async lastChronometerTimer() {
        await this.appStorageService.setLastChronometerTimer({
            hours: this.hours,
            minutes: this.minutes,
            seconds: this.seconds
        });

        this.appStorageService.hours = this.hours;
        this.appStorageService.minutes = this.minutes;
        this.appStorageService.seconds = this.seconds;
    }

    private async loadContractInformation(contractData?: Contract) {
        let contract: Contract | undefined = contractData;
        let response: any = {success: true, data: contract};

        if (!contract) {
            contract = await this.appStorageService.getCurrentContract() as Contract;
            if (contract) {
                response = await this.contractService.getContract(contract.id!).toPromise();
                if (response.success) {
                    await this.appStorageService.setCurrentContract(response.data);
                }
            }
        }

        const normalizeContractResponse: any = EmployeeRegisterTimeComponent.normalizeContractResponse(response.data);
        const startTimeFirstPeriod = normalizeContractResponse.startTimeFirstPeriod;
        const endTimeFirstPeriod = normalizeContractResponse.endTimeFirstPeriod;
        const startTimeSecondPeriod = normalizeContractResponse.startTimeSecondPeriod;
        const endTimeSecondPeriod = normalizeContractResponse.endTimeSecondPeriod;

        const durationIni = moment.duration(startTimeFirstPeriod.diff(endTimeFirstPeriod)).asHours();
        const durationFim = moment.duration(endTimeSecondPeriod.diff(startTimeSecondPeriod)).asHours();

        this.workDayTime = durationIni + durationFim;
        const interval = moment.duration(startTimeSecondPeriod.diff(startTimeFirstPeriod)).asHours();
        this.iniStop = moment.duration(startTimeFirstPeriod.diff(endTimeFirstPeriod)).asHours();
        this.endStop = this.iniStop + interval;
        this.normalizedContractResponse = normalizeContractResponse;
        this.homeOffice = this.normalizedContractResponse.homeOffice;
        await this.restoreClockTimer();
    }

    /**
     * Send new data entry point
     *
     */
    private async register(data: RegisterClock) {

        const response: HttpRegisterClockResponse | undefined = await this.registerClockService.addClockRegister(data).toPromise();

        if (response!.success) {
            this.started = data.type === 'E';

            if (data.type === 'S') {
                this.chronometer!.stop();
                await this.appStorageService.setTimerMarker(`${this.hours}h : ${this.minutes}m`);
                await this.appStorageService.setLastRegisterStopClock(data);
            } else {
                this.chronometer!.start();
                await this.appStorageService.setLastRegisterClock(data);
            }
        } else {
            this.loadMessage = '';
            this.loading = false;
            this.messageService.add({
                severity: 'warn',
                summary: 'Erro na inicialização da Jornada',
                detail: response!.message,
            });
        }
        this.loadMessage = '';
        this.loading = false;
        this.getPeriodsOfDay();
    }

    private async getCachedContract(): Promise<any> {
        const contract = await this.appStorageService.getCurrentContract();
        return EmployeeRegisterTimeComponent.normalizeContractResponse(contract);
    }

    /**
     * Return the current work day period
     */
    private async getPeriod() {

        const config: ContractPeriod = await this.getCachedContract();

        const today = moment().isoWeekday();
        const journeyJson: any =  JourneyHelp.getJourneyJson(config?.journey);
        const startTimeFirstPeriod = moment(journeyJson[today][0], 'HH:mm');
        const endTimeFirstPeriod = moment(journeyJson[today][1], 'HH:mm');
        const startTimeSecondPeriod = moment(journeyJson[today][2], 'HH:mm');
        const endTimeSecondPeriod = moment(journeyJson[today][3], 'HH:mm');

        let beforeTime = moment({hour: endTimeFirstPeriod!.get('hour'), minute: endTimeFirstPeriod!.get('minute')});
        let afterTime = moment(
            {hour: startTimeFirstPeriod!.get('hour'), minute: startTimeFirstPeriod!.get('minute')}
        ).subtract(10, 'minutes');

        if (moment().isBetween(afterTime, beforeTime)) {
            return 'primeiro_turno';
        }

        beforeTime = moment({hour: endTimeSecondPeriod!.get('hour'), minute: endTimeSecondPeriod!.get('minute')});
        afterTime = moment(
            {hour: startTimeSecondPeriod!.get('hour'), minute: startTimeSecondPeriod!.get('minute')}
        ).subtract(10, 'minutes');

        if (moment().isBetween(afterTime, beforeTime)) {
            return 'segundo_turno';
        }

        beforeTime = moment(
            {hour: startTimeSecondPeriod!.get('hour'), minute: startTimeSecondPeriod!.get('minute')}
        ).subtract(10, 'minutes');
        afterTime = moment({hour: endTimeFirstPeriod!.get('hour'), minute: endTimeFirstPeriod!.get('minute')});

        if (moment().isBetween(afterTime, beforeTime)) {
            return 'intervalo';
        }

        return 'fora_da_jornada';
    }

    /**
     * Calculate distance between two geolocation position
     *
     */
    private getDistanceFromPosition(latitude: string, longitude: string, company: any): number {
        return (this.employeeService.calculateDistance(
            parseFloat(latitude),
            parseFloat(longitude),
            parseFloat(company.data.latitude),
            parseFloat(company.data.longitude))) * 1000;
    }

    private getLocationTolerance(object: any): number {

        if (object.geo_location_type && object.geo_location_type === 'RANGE_INTERPOLATED') {
            return 300;
        }
        return 150;
    }

    private resetTimer(): void {
        this.hours = 0;
        this.minutes = 0;
        this.seconds = 0;
        this.started = false;
    }

    private async getLastRegisterClock(): Promise<RegisterClock> {
        const contract = await this.getCachedContract();
        const date = moment().startOf('day').format('YYYY-MM-DD');
        const response: HttpRegistersClockResponse | undefined =
            await this.registerClockService.getClockRegistersByDate(contract.id, date).toPromise();

        const sorted = response!.data.slice().sort((a: any, b: any) =>
            moment(a.recordDateTime, 'YYYY-MM-DD HH:mm:ss').toDate().getTime() -
            moment(b.recordDateTime, 'YYYY-MM-DD HH:mm:ss').toDate().getTime());

        return sorted.pop()!;
    }

    /**
     * Restore the last clock timer value
     *
     */
    private async restoreClockTimer(): Promise<void> {
        const contract: ContractPeriod = await this.getCachedContract();
        const chronometerTimer = await this.appStorageService.getLastChronometerTimer();


        console.log('restoreClockTimer', chronometerTimer);
        console.log('restoreClockTimer', contract);

        if (chronometerTimer) {
            this.hours = chronometerTimer.hours;
            this.minutes = chronometerTimer.minutes;
            this.seconds = chronometerTimer.seconds;
        }
        if (contract) {
            const lastRegisterClock: RegisterClock = await this.getLastRegisterClock();
            console.log('restoreClockTimer', lastRegisterClock);

            if (lastRegisterClock) {
                const now = moment();
                const currentTimer = moment(lastRegisterClock.recordDateTime, 'YYYY-MM-DD HH:mm:ss');
                const lastStopRegisterClock: RegisterClock = await this.appStorageService.getLastRegisterStopClock();

                if (!currentTimer.isSame(now, 'day')) {
                    await this.appStorageService.removeTimerMarkers();

                    if (lastStopRegisterClock === null) {
                        await this.appStorageService.removeLastRegisterClock();
                        await this.stopRegisterClock(contract.endTimeSecondPeriod!.clone().subtract(now.diff(currentTimer, 'days'), 'days'));
                    }
                    return;
                }

                // TODO implement the hora ini manha logic

                if (moment().isAfter(contract.endTimeSecondPeriod!.clone().add(2, 'hours'))) {
                    await this.appStorageService.removeLastRegisterClock();
                    await this.stopRegisterClock(contract.endTimeSecondPeriod!.clone().add(2, 'hours'));
                    return;
                }

                if (lastRegisterClock.type === 'E') {
                    this.resetTimer();
                    this.started = true;
                    await this.startChronometer(moment.duration(now.diff(currentTimer)));
                }
            }
        }
    }

    private async startChronometer(duration?: moment.Duration) {

        const chronometerTimer = await this.appStorageService.getLastChronometerTimer();

        if (chronometerTimer) {
            this.hours = chronometerTimer.hours ? chronometerTimer.hours : 0;
            this.minutes = chronometerTimer.minutes ? chronometerTimer.minutes : 0;
            this.seconds = chronometerTimer.seconds ? chronometerTimer.seconds : 0;
        }

        if (duration) {
            this.hours = duration.hours() > 0 ? (duration.hours() - this.hours) + this.hours  : this.hours;
            this.minutes = duration.minutes() > 0 ? (duration.minutes() - this.minutes) + this.minutes : this.minutes;
            this.seconds = duration.seconds() > 0 ? (duration.seconds() - this.seconds) + this.seconds  : this.seconds;
        }

        this.started = true;
        this.chronometer!.start();
    }

    async onClockAdjustment(clock: any): Promise<void> {
        // const contract = await this.appStorageService.getCurrentContract();
        const journeyDay = moment(clock.recordDate, "YYYY-MM-DD").isoWeekday();
        const journeyJson: any = JourneyHelp.getJourneyJson(this.contractLocal?.journey);

        this.form!.patchValue({
            startTimeFirstPeriod: journeyJson[journeyDay][0],
            endTimeFirstPeriod: journeyJson[journeyDay][1],
            startTimeSecondPeriod: journeyJson[journeyDay][2],
            endTimeSecondPeriod: journeyJson[journeyDay][3],
        });

        // this.form!.patchValue({
        //     startTimeFirstPeriod: this.contractLocal!.startTimeFirstPeriod,
        //     endTimeFirstPeriod: this.contractLocal!.endTimeFirstPeriod,
        //     startTimeSecondPeriod: this.contractLocal!.startTimeSecondPeriod,
        //     endTimeSecondPeriod: this.contractLocal!.endTimeSecondPeriod,
        // });

        try {
            this.form.get('recordDate')!.setValue(clock.recordDate),
                this.showClockAjustment = true;
        } catch (error) {
            console.log(error);
        }
    }

    async sendClockAdjustment() {
        const clockAdjustment: any = {
            contractId: this.contractLocal.id,
            companyId: this.contractLocal.companyId,
            employeeId: this.contractLocal.employeeId,
            departmentId: this.contractLocal.departmentId,
            latitude: this.contractLocal.latitude,
            longitude: this.contractLocal.longitude,
            recordDate: this.form.get('recordDate')!.value,
            startTimeFirstPeriod: this.form.get('startTimeFirstPeriod')!.value,
            endTimeFirstPeriod: this.form.get('endTimeFirstPeriod')!.value,
            startTimeSecondPeriod: this.form.get('startTimeSecondPeriod')!.value,
            endTimeSecondPeriod: this.form.get('endTimeSecondPeriod')!.value,
        };

        await this.clockAdjustmentService.addClockAdjustment(clockAdjustment).toPromise().then(response => {
            this.clockAdjustmentTemp = response;
        });

        this.onShowClockAjustmentSuccess('top-right');
        this.showClockAjustment = false;

        await this.findEmployeeByDates();
    }

    async onRejectClockAdjustment(clockAdjustmentResult: any): Promise<void> {
        try {
            const clocAdjustment: any = {
                contractId: clockAdjustmentResult.data.contractId,
                companyId: clockAdjustmentResult.data.companyId,
                employeeId: clockAdjustmentResult.data.employeeId,
                departmentId: clockAdjustmentResult.data.departmentId,
                latitude: clockAdjustmentResult.data.latitude,
                longitude: clockAdjustmentResult.data.longitude,
                recordDate: clockAdjustmentResult.data.recordDate,
                startTimeFirstPeriod: clockAdjustmentResult.data.startTimeFirstPeriod,
                endTimeFirstPeriod: clockAdjustmentResult.data.endTimeFirstPeriod,
                startTimeSecondPeriod: clockAdjustmentResult.data.startTimeSecondPeriod,
                endTimeSecondPeriod: clockAdjustmentResult.data.endTimeSecondPeriod,
            };
            const clockAdjustmentId = clockAdjustmentResult.data.id;

            await this.clockAdjustmentService.rejectClockAdjustment(clocAdjustment, clockAdjustmentId).toPromise();

            this.onCloseClockAjustmentSuccess();
        } catch (error) {
            console.log(error);
            this.onCloseClockAjustmentSuccess();
        }
    }

    async onCloseHoursAdjustment(): Promise<void> {
        this.showClockAjustment = false;
        await this.findEmployeeByDates();
    }

    async onShowClockAjustmentSuccess(position: string): Promise<void> {
        this.modalPosition = position;
        this.showClockAjustmentSuccess = true;
    }

    async onCloseClockAjustmentSuccess(): Promise<void> {
        this.clockAdjustmentTemp = null;
        this.showClockAjustmentSuccess = false;
        await this.findEmployeeByDates();
    }
}
